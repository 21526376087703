import React from "react"
// import { useEventListener } from "@hooks/useEventListener"

import { gsap } from "gsap";
import { PixiPlugin } from "gsap/PixiPlugin"

import vertexShader from "raw-loader!@components/shader/shader.vert"
import fragmentShader from "raw-loader!@components/shader/shader.frag"

// import * as PIXI from "pixi.js"
// import * as FIBER from "react-pixi-fiber"

let PIXI
let FIBER

gsap.registerPlugin(PixiPlugin)

if (typeof window !== 'undefined') {
    PIXI = require('pixi.js')
    FIBER = require('react-pixi-fiber')
    PixiPlugin.registerPIXI(PIXI)
    
}

function Pixi({className}) {
    // const stateRef = React.useRef()
    const [render, setRender] = React.useState(0)
    const size = React.useRef({
        width: typeof window === 'undefined' ? 0 : window.innerWidth,
        height: typeof window === 'undefined' ? 0 : window.innerHeight
    })
    const options = React.useRef({
        backgroundColor: 0xFFFFFF,
        antialias: false,
        resolution: 1,
        autoResize: false,
        ...size.current,
    })
    // const styles = {
    //     width: `100vw`,
    //     height: '100vh',
    //     position: 'fixed',
    //     top: 0,
    //     left: 0,
    //     zIndex: -1,
    // }

    React.useEffect(() => {
        if (typeof window === 'undefined') return
        size.current = {
            width: window.innerWidth,
            height: window.innerHeight
        }
        options.current.resolution = window.devicePixelRatio || 1
        if (render === 0) {
            setRender(1)
        }
        console.log('rendering')

    }, [render])

    // useEventListener('resize', () => {
    //     setRender(render + 1)
    // })
    // console.log('render')

    return (
        <div>
            { FIBER && render !== 0 ?
                <FIBER.Stage className={className} options={options.current}>
                    <FIBER.AppContext.Consumer>
                        { app => {
                            app.renderer.plugins.interaction.autoPreventDefault = false
                            app.renderer.view.style.touchAction = 'auto'
                            return(
                                <Content size={size.current} />
                            )
                        }}
                    </FIBER.AppContext.Consumer>
                </FIBER.Stage>
                :
                <div />
            }
        </div>
    )
}

/* ========================================================================
*
*    content
*
======================================================================== */
function Content({ size }) {
    // const app = FIBER.usePixiApp

    const containerRef = React.useRef()
    const time = React.useRef(0)
    const mesh = React.useRef()

    const geometry = new PIXI.Geometry()
        .addAttribute('aVertexPosition', // the attribute name
            [
                0, 0,
                size.width, 0,
                size.width, size.height,
                0, size.height
            ],
            2) // the size of the attribute
        .addAttribute('aColor', // the attribute name
            [
                1, 0, 0,
                0, 1, 0,
                0, 0, 1,
                0, 0, 1,
            ], // r, g, b
            3) // the size of the attribute
        .addAttribute('aUvs', // the attribute name
            [0, 0, // u, v
                1, 0, // u, v
                1, 1,
                0, 1], // u, v
            2) // the size of the attribute
        .addIndex([0, 1, 2, 0, 2, 3])

    const shader = PIXI.Shader.from(
        `${vertexShader}`,
        `${fragmentShader}`,
        {
            uResolution: [size.width, size.height],
            uTime: 0,
            uMouse: [0, 0],
        }
    )
    mesh.current = new PIXI.Mesh(geometry, shader)

    //renderd
    React.useEffect(() => {
        containerRef.current.addChild(mesh.current)
    }, [])

    //ticker
    FIBER.usePixiTicker(() => {
        time.current += 1 / 60
        let u = mesh.current.shader.uniforms
        u.uTime = time.current
        u.uMoust = [0, 0]
    })

    return (
        <FIBER.Container ref={containerRef}>
        </FIBER.Container>
    )
}

export { Pixi }