import * as React from "react"
import { graphql} from "gatsby"
// import { Image } from "@components/atoms/Image"
// import { StaticImage } from "gatsby-plugin-image"
import SEO from "@components/atoms/Seo"
import Hero from "@components/projects/IndexHero"
import BlogList from "@components/projects/BlogList"
import { CategoryList } from "@components/projects/CategoryList"
import { Section, SectionHeader } from "@atoms/Section"


function IndexPage({data})
{
    const { blog } = data
    return (
        <>
            <SEO/>
            <Hero/>
            <Section>
                <SectionHeader title={"LOG"}>
                    <CategoryList />
                </SectionHeader>
                <BlogList data={blog}/>
            </Section>
        </>
    )
}

export const pagerQuery = graphql`
    query {
        blog : allContentfulLog(
            sort: { fields: [date], order: DESC }
            limit: 1000
        ) {
            edges {
                node {
                    ...Blog
                }
            }
        }
    }
`
export default IndexPage
