import React from "react"
import * as css from "@css/projects/p-index-hero.module.styl"
// import { useEventListener } from "@hooks/useEventListener";
import { Pixi } from "@projects/Pixi"

export default function IndexHero()
{
    let copy1 = "REPAVER"
    let copy2 = "今より、確かな道に。"
    let dom1 = React.useMemo(()=>{
        let arr = []
        let split = copy1.split('')
        for (let i in split){
            arr.push(<span key={i}>{split[i]}</span>)
        }
        return arr
    })
    let dom2 = React.useMemo(() => {
        let arr = []
        let split = copy2.split('')
        for (let i in split) {
            arr.push(<span key={i}>{split[i]}</span>)
        }
        return arr
    })
    const titleRef = React.useRef()
    const subtitleRef = React.useRef()
    React.useEffect(()=>{
        setTimeout(()=>{
            titleRef.current.classList.add(css.pIndexHero__titleVisible)
        },1500)
        setTimeout(() => {
            subtitleRef.current.classList.add(css.pIndexHero__subtitleVisible)
        }, 1800)
    },[])
    return(
        <section className={css.pIndexHero}>
            <Pixi className={css.pIndexHero__bg}/>
            <div className={css.pIndexHero__inner}>
                <div className={css.pIndexHero__content}>
                    <h1 className={css.pIndexHero__title} ref={titleRef}>
                        { dom1 }
                    </h1>
                    <h2 className={css.pIndexHero__subtitle} ref={subtitleRef}>
                        { dom2 }
                    </h2>
                </div>
            </div>
        </section>
    )
}
